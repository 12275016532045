<template>
  <div>
    <div class="book" v-html="compiledMarkdown"></div>
  </div>
</template>

<script>
import marked from 'marked';

export default {
  name: 'Book',

  data() {
    return {
      input: '',
    };
  },

  props: {
    bookName: {
      type: String,
      default: 'book1',
    },
  },

  computed: {
    compiledMarkdown() {
      return marked(this.input);
    },
  },

  mounted() {
    this.$http
      .get(`/book/${this.bookName || this.$store.state.currentBook}`)
      .then((res) => {
        this.input = res.data.content;
      });
  },
};
</script>

<style lang="scss"></style>
