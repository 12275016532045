<template>
  <div>
    <div class="row" v-if="!showBook">
      <div class="heading d-flex justify-content-between px-4">
        <h1 class="h1">{{ $t("comp_bookList--title") }}</h1>
      </div>
      <transition name="fade" mode="out-in">
        <div class="row px-4">
          <div class="mybook" v-for="book in books" :key="book.name">
            <div class="card book-wrapper" @click="openBook(book.name)">
              <!-- <div class="card-header">{{ $t('comp_book--cardTitle') }}</div> -->
              <div class="card-body book">
                <div class="book-img-wrapper">
                  <img class="book-img" :src="getBookUrl(book.name)" alt="" />
                </div>
                <div>
                  <h5 class="card-title book-title py-2">
                    {{ book.name
                    }}<span v-if="isBookNameLengthLong(book.name)">...</span>
                  </h5>
                </div>
                <span class="book book-badge">
                  Open
                </span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>
      <div class="row">
        <div class="d-flex flex-row align-items-center">
          <a class="d-flex box-icon my-3 me-3" @click="goBack()">
            <i class="bx bx-chevron-left"></i>
            <div>Back</div>
          </a>
        </div>
      </div>
      <div class="row">
        <Book :bookName="bookName" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Book from '@/common/widgets/BookLibraryWidget/Book.vue';

export default {
  name: 'BookLibrary',
  data() {
    return {
      bookName: '',
      singleBook: false,
    };
  },

  components: {
    Book,
  },

  computed: {
    books() {
      return this.getBooks();
    },
    showBook() {
      return !!this.$store.state.currentBook;
    },
  },

  methods: {
    getBookUrl(bookName) {
      const bookwithoutInt = bookName.trim().split('-').slice(1).join('-');
      const finalBook = bookwithoutInt.split('.md')[0];
      return `https://thoughtjumper-images.s3.ap-south-1.amazonaws.com/assets/${finalBook}/cover.jpg`;
    },
    ...mapGetters({
      getBooks: 'getBooks',
    }),

    ...mapActions({
      fetchBooks: 'fetchBooks',
    }),

    openBook(bookName) {
      this.$store.dispatch('setCurrentBook', bookName);
    },

    goBack() {
      this.$store.dispatch('clearCurrentBook');
    },

    isBookNameLengthLong(bookName) {
      return bookName.length > 20;
    },
  },

  created() {
    this.fetchBooks();
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-maxmin {
  opacity: 0.8;
  transition: all ease-out 200ms;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: all ease-out 200ms;
  }
}
.book-img {
  height: 100%;
  width: 100%;
}
.card-body {
  padding: unset !important;
  display: flex;
}

.book-wrapper {
  border-radius: 0 0 0.625rem 0;
  &:hover {
    cursor: pointer;
  }
}

.mybook {
  width: 18rem;
  margin: 1em;
}

.book {
  // margin: 1em;
  border-radius: 0.625em;
  position: relative;

  &-title {
    font-size: .7rem;
  }

  &-img-wrapper {
    height: 10em;
    min-width: 7em;
    margin-right: 1em;
  }

  &-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #242424;
    border-radius: 0.625em 0 0.625em 0;
    padding: 0.3em 0.7em;
    font-size: 0.625rem;
    color: #ffffff;
  }
}
</style>
